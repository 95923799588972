import * as React from "react";

import DownloadIcon from "@mui/icons-material/CloudDownload";
import { Alert, AlertTitle, Grid, Link } from "@mui/material";
import { AlertColor } from "@mui/material/Alert/Alert";
import { makeStyles } from "@mui/styles";

import { IMinReconciliationReportStatusDetailsResponse } from "./minReconciliationAdapter";

const useStyles = makeStyles({
  alert: {
    "&:hover": {
      cursor: "pointer",
    },
  },
});

interface IMersGroupStatusProps {
  details: IMinReconciliationReportStatusDetailsResponse;
  count: number;
  id: string;
  loading: boolean;
  totalCount: number;
}

/**
 * Component that displays a status group for the reconciliation report.
 */
function MinReconciliationReportGroupStatus({ details, id }: IMersGroupStatusProps): JSX.Element {
  const classes = useStyles();
  const severity: AlertColor = details.severity.toLowerCase() as AlertColor;
  const query = `?status=${details.type}`;

  return (
    <Grid item xs={3} xl={2}>
      <Link href={`/api/v1/mers/reconciliation-reports/${id}/export${query}`} underline="none" target="_blank">
        <Alert action={<DownloadIcon />} className={classes.alert} severity={severity} variant="filled">
          <AlertTitle>{details.count ?? 0}</AlertTitle>
          {details.description}
        </Alert>
      </Link>
    </Grid>
  );
}

export default MinReconciliationReportGroupStatus;
