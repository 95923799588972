import React, { ReactElement, useEffect, useMemo } from "react";

import { ConnectedProps, connect } from "react-redux";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";

import { RootState } from "App/Store";

import { nonce } from "../../../App/App";
import ErrorOverlay from "../../../Components/DataGridCustomComponents/ErrorOverlay";
import LoadingOverlay from "../../../Components/DataGridCustomComponents/LoadingOverlay";
import Pagination from "../../../Components/DataGridCustomComponents/Pagination";
import MinReconciliationReportIdColumn from "./MinReconciliationReportIdColumn";
import { executeSearch, fetchMinReconciliationReportsAction } from "./minReconciliationReportService";

interface ISearchResultsTabProps {
  keyRoot: string;
}

const mapStateToProps = (state: RootState) => ({
  error: state.minReconciliation.error,
  initialized: state.minReconciliation.initialized,
  isLoading: state.minReconciliation.isLoading,
  links: state.minReconciliation.links,
  minReconciliationProps: state.minReconciliation,
  profile: state.profile,
  searchResults: state.minReconciliation.searchResult,
});

const connector = connect(mapStateToProps);

type TypesFromRedux = ConnectedProps<typeof connector>;

type ComponentProps = ISearchResultsTabProps & TypesFromRedux;

function MinReconciliationReportSearchResultsTable(props: ComponentProps): ReactElement {
  const { dispatch, error, initialized, isLoading, links, searchResults } = props;

  useEffect(() => {
    if (!initialized) {
      (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchMinReconciliationReportsAction());
    }
  }, [dispatch, initialized]);

  const searchColumns: GridColDef[] = useMemo(() => {
    return [
      {
        editable: false,
        field: "createdDate",
        flex: 1,
        headerName: "Created",
        renderCell: (params: GridRenderCellParams) => {
          return <MinReconciliationReportIdColumn minReconciliationReport={params.row} />;
        },
        sortable: false,
      },
      {
        editable: false,
        field: "mersOrgId",
        flex: 1,
        headerName: "MERS Org Id",
        sortable: false,
      },
      {
        editable: false,
        field: "minsProcessed",
        flex: 1,
        headerName: "MINs Processed",
        sortable: false,
      },
      {
        editable: false,
        field: "totalErrors",
        flex: 1,
        headerName: "Total Errors",
        sortable: false,
      },
    ];
  }, []);

  function handleNextPage() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchMinReconciliationReportsAction(links?.next));
  }

  function handlePreviousPage() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(fetchMinReconciliationReportsAction(links?.previous));
  }

  function handleRetry() {
    (dispatch as ThunkDispatch<any, undefined, AnyAction>)(executeSearch());
  }

  return (
    <DataGrid
      columnBuffer={0}
      columns={searchColumns}
      components={{
        ErrorOverlay: ErrorOverlay,
        LoadingOverlay: LoadingOverlay,
        Pagination: Pagination,
      }}
      componentsProps={{
        errorOverlay: { error, onRetry: handleRetry },
        pagination: { handleNextPage, handlePreviousPage, isLoading, links },
      }}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableSelectionOnClick
      error={error}
      loading={isLoading}
      localeText={{
        noRowsLabel: "No results found",
      }}
      nonce={nonce}
      pagination
      paginationMode="server"
      rows={searchResults}
      rowsPerPageOptions={[100]}
      scrollbarSize={17}
      sortingMode="server"
      sortingOrder={["desc", "asc"]}
    />
  );
}

export default connector(MinReconciliationReportSearchResultsTable);
