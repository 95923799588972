import _ from "lodash";

import { ICollapsibleSideMenuListSubItem } from "Features/Navigation/CollapsibleSideMenu/CollapsibleSideMenuInterfaces";

import { IProfileStore } from "../Profile/ProfileSlice";
import { canTransfer } from "./Mers/mersService";
import { Roles } from "./VaultInterfaces";

export function getAuthorizedVaultMenuOptions(profileStore: IProfileStore): ICollapsibleSideMenuListSubItem[] {
  const vaultNavigation: ICollapsibleSideMenuListSubItem[] = [];
  const validRolesForVaultAccess: Roles[] = [
    "evault.admin",
    "evault.loan_officer",
    "evault.mers.admin",
    "evault.mers.registrar",
    "evault.mers.transferor",
    "evault.reader",
  ];
  const profile = profileStore.profile;
  const hasAccessToVaultOptions = _.intersection(profile.roles, validRolesForVaultAccess).length > 0;
  if (hasAccessToVaultOptions) {
    vaultNavigation.push({
      id: "left-nav-vault-all",
      title: "All",
      to: "/app/vault/all",
    });
    vaultNavigation.push({
      id: "left-nav-vault-closed",
      title: "Closed",
      to: "/app/vault/closed",
    });
    vaultNavigation.push({
      id: "left-nav-vault-archived",
      title: "Archived",
      to: "/app/vault/archived",
    });
    vaultNavigation.push({
      id: "left-nav-vault-inactive",
      title: "Inactive",
      to: "/app/vault/inactive",
    });
  }
  if (canTransfer(profile.roles)) {
    vaultNavigation.push({
      id: "left-nav-vault-incoming-deliveries",
      title: "Incoming",
      to: "/app/vault/incoming",
    });
  }
  if (
    canTransfer(profile.roles) &&
    (profile.settings.mers.securedPartyEnabled || profile.settings.mers.securedPartyDelegateeEnabled)
  ) {
    vaultNavigation.push({
      id: "left-nav-vault-queue",
      title: "Queue",
      to: "/app/vault/queue",
    });
  }
  if (canTransfer(profile.roles)) {
    vaultNavigation.push({
      id: "left-nav-vault-batch-operations",
      title: "Batch Operations",
      to: "/app/vault/batch-operations",
    });
  }

  if (profile.settings.mers.minReconciliationEnabled) {
    vaultNavigation.push({
      id: "left-nav-vault-min-reconciliations",
      title: "MIN Reconciliations",
      to: "/app/vault/min-reconciliation",
    });
  }

  return vaultNavigation;
}
