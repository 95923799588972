/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */

/** @jsxImportSource @emotion/react */
import React, { useState } from "react";

import { css } from "@emotion/react";
import { formatInTimeZone } from "date-fns-tz";

import { Refresh } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";

import { IMinReconciliationReport } from "./MinReconciliationReportInterfaces";
import MinReconciliationReportStatus from "./MinReconciliationReportStatus";
import { IMinReconciliationReportStatusResponse, getMinReconciliationReportStatus } from "./minReconciliationAdapter";

interface IMinReconciliationReportResults {
  report: IMinReconciliationReport;
}

/**
 * Component for reconciliation report results. This should contain
 */
export default function MinReconciliationReportDetails(props: IMinReconciliationReportResults): JSX.Element {
  const { report } = props;
  const { id } = report;
  const [data, setData] = React.useState<IMinReconciliationReportStatusResponse | undefined>(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [totalCount, setTotalCount] = React.useState<number>(0);
  const [refreshDate, setRefreshDate] = useState<Date | undefined>();

  React.useEffect(() => {
    /**
     * Fetches the status for the reconciliation report.
     */
    async function getReportStatus() {
      setLoading(true);
      const response = await getMinReconciliationReportStatus(id);
      setData(response.data);
      setTotalCount(response.data.count);
      setLoading(false);
    }
    void getReportStatus();
  }, [id, refreshDate]);

  /**
   * Sets the date refresh date (forces a refresh of the status)
   */
  function handleRefresh() {
    setRefreshDate(new Date());
  }

  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "space-between", ml: 2, mr: 2, mt: 2 }}>
        <Typography variant="h5">
          {formatInTimeZone(new Date(report.createdDate), "America/Boise", "M/d/yyyy h:mm a zzz")}
        </Typography>

        <Button
          color="secondary"
          css={css`
            margin-left: 8px;
            min-width: 100px;
          `}
          onClick={handleRefresh}
          startIcon={<Refresh />}
          type="button"
          variant="contained"
        >
          REFRESH STATUS
        </Button>
      </Box>
      <Typography sx={{ ml: 2, mt: 1 }} variant="h6">
        Details
      </Typography>

      {data != null && (
        <>
          <Grid container spacing={1} sx={{ ml: 1 }}>
            {data.details.map((details) => {
              return (
                <MinReconciliationReportStatus
                  count={details.count}
                  details={details}
                  key={details.type}
                  id={id}
                  loading={loading}
                  totalCount={totalCount}
                />
              );
            })}
          </Grid>
        </>
      )}
    </div>
  );
}
